import React, { useContext } from "react";
import { useNavigate } from "react-router";
import "../comp/Subscription.css";
import Context from "../../Context/Context";
import BworkzInstructorMonthly from "../Subscription/BworkzInstructorMonthly";
import BworkzInstructorYearly from "../Subscription/BworkzInstructorYearly";
import RazorpayPayment from '../Subscription/RazorpayPayment'
export default function Subscription() {
    const Ctx = useContext(Context);
    const Navigate = useNavigate();
    console.log(Ctx.productList)
    const UserCtx = useContext(Context).userData
    return (
        <>
            <section className="Back text-[1.5rem]  flex  flex-col items-center h-[90rem] max980:h-[auto] justify-center gap-[5rem] pb-20 ">
                <div className="text-center mt-20">
                    <h1>INSTRUCTOR TRAINING FEES</h1>
                    <h3 className="text-[1rem]">see what are the pricing in details</h3>
                </div>
                <div className="flex flex-wrap justify-center w-[90vw] max-w-[80rem] gap-[8rem] ">
                    {/* <BworkzInstructorMonthly />
<BworkzInstructorYearly /> */}
                    <div className="bg-white w-[24rem] h-[32rem] p-10 rounded-[2rem]  flex flex-col items-center gap-8 shadowSubscribe   max450:w-[90vw] max450:gap-4 max450:text-[1rem] max450:min-h-[28rem] max450:h-auto max450:p-12 border-[#FDCF08] border-[0.1rem]">
                        <p className="">BWORKZ Choreography Monthly</p>
                        <p>Monthly Subscription Through PayPal</p>
                        <h1 className="text-left w-[100%]">$ 20.00 / Month</h1>
                        {Ctx.isAuth ? (
                            <BworkzInstructorMonthly />
                        ) : (
                            <button
                                onClick={() => {
                                    Navigate("/signup");
                                }}
                                className="w-[15rem] butun bg-[#FDCF08] text-white px-12 py-2 rounded-2xl hover:text-[#FDCF08]  hover:border-[#FDCF08] hover:border-[0.3rem] h-[3rem] flex justify-center items-center max450:w-[60vw]"
                            >
                                Subscribe
                            </button>
                        )}
                    </div>
                    <div className="bg-white w-[24rem] h-[32rem] p-10 rounded-[2rem]  flex flex-col items-center gap-8 shadowSubscribe max450:text-[1rem] max450:min-h-[28rem] max450:h-auto max450:p-12  border-[#FDCF08] border-[0.1rem]">
                        <p className="">BWORKZ Instructor Yearly</p>
                        <p>Yearly Subscription Through PayPal</p>
                        <h1 className="text-left w-[100%]">$ 200.00 / Yearly</h1>
                        {Ctx.isAuth ? (
                            <BworkzInstructorYearly />
                        ) : (
                            <button
                                onClick={() => {
                                    Navigate("/signup");
                                }}
                                className="w-[15rem] butun bg-[#FDCF08] text-white px-12 py-2 rounded-2xl hover:text-[#FDCF08]  hover:border-[#FDCF08] hover:border-[0.3rem] h-[3rem] flex justify-center items-center max450:w-[60vw]"
                            >
                                Subscribe
                            </button>
                        )}
                    </div>
                    <div className={`flex flex-wrap justify-center w-[90vw] max-w-[80rem] gap-28 `}>
                    {Ctx.productList.map((item, i) => {
            return (
              <li
                key={item.productId + `home${i}`}
                className={` w-[24rem] h-auto p-10 rounded-[2rem] z-0  flex flex-col items-center gap-8 shadowSubscribe   max450:w-[90vw] max450:gap-4 max450:text-[1rem] max450:min-h-[28rem] max450:h-auto max450:p-12 border-[0.1rem] bg-white`}
                style={{
                  borderColor: "#FDCF08"
                }}
              >
                <p className={` `}>{item.heading}</p>
                <ul className={` h-auto pl-0 flex flex-col`}>
                  {item.provides.map((item, i) => {
                    return (
                      <li key={`${i}ok`}>
                        <p>{item}</p>
                      </li>
                    )
                  })}
                </ul>
                <h1 className={`text-left w-[100%]`}>
                  {(item.currency === 'INR' ? '₹ ' : '$ ') +
                    parseInt(item.amount) / 100 +
                    '/' +
                    item.durationText}
                </h1>
                {Ctx.isAuth ? (
                  <div className="z-1">
                    {UserCtx.status === 'Active' ? (
                      <p
                        className={`text-[1rem] w-[15rem] px-12 py-2 rounded-2xl bg-white border-[0.2rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw]`}
                        style={{
                          color: "#FDCF08",
                          borderColor: "#FDCF08"
                        }}
                      >
                        Already Subscribed
                      </p>
                    ) : (
                      <>
                       
  <RazorpayPayment productId={item.productId} />

                      </>
                    )}
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      Navigate('/signup')
                    }}
                    className={`w-[15rem] text-white px-12 py-2 rounded-2xl hover:text-lightPrimaryColor hover:border-lightPrimaryColor hover:border-[0.3rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw]`}
                    style={{
                      backgroundColor: "#FDCF08"
                    }}
                  >
                    Sign Up
                  </button>
                )}
              </li>
            )
          })}
                </div></div>
            </section>
        </>
    );
}

